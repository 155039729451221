<template>
  <layout name="NoColumns">
    <iframe
      id="trends-embedded"
      src="https://my-vue-app-hz7d5dkymq-lz.a.run.app"
      @load="resizeIframe"
    />
  </layout>
</template>

<script>
import { loadScript } from '@hypefactors/shared/js/utils'

export default {
  methods: {
    resizeIframe () {
      window.iFrameResizer = {}
      loadScript('https://cdn.jsdelivr.net/npm/@iframe-resizer/parent@5.3.2', 'iFrameResizer')
        .then(() => {
          window.iframeResize({
            license: 'GPLv3',
            waitForLoad: false
          }, '#trends-embedded')
        })
    }
  }
}
</script>

<style lang="scss" scoped>
#trends-embedded {
  width: 100%;
  height: 100vh;
}
</style>
